import React from "react";
import _ from "lodash/fp";
import { defaultStructureNoteColumns } from "@truedat/dd/selectors";
import { DateDecorator } from "@truedat/core/services/columnDecorators";

const defaultColumnsWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureNoteColumns);

const columns = [
  {
    name: "type",
    sort: { name: "type.sort" },
    width: 2
  },
  {
    name: "note_last_changed_by.user_name",
    sort: { name: "note_last_changed_by.user_name.sort" },
    width: 2
  },
  {
    name: "note_last_changed_at",
    sort: { name: "note_last_changed_at" },
    width: 2,
    fieldSelector: ({ note_last_changed_at }) => ({
      date: note_last_changed_at
    }),
    fieldDecorator: DateDecorator
  }
];

const initialState = [...defaultColumnsWithoutGroup, ...columns];

export const structuresNotesColumns = (state = initialState) => state;
