import { defaultExecutionGroupColumns } from "@truedat/dq/selectors/getExecutionGroupColumns";

const customColumns = [
  {
    name: "implementation.Quality Principles",
    fieldSelector:
      "_embedded.implementation.dynamic_content.Quality Principles.value"
  }
];

const initialState = [...defaultExecutionGroupColumns, ...customColumns];

export const executionGroupColumns = (state = initialState) => state;
